import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FeaturedPosts from '../components/blog/featured-posts-fr';

import homepage from '../images/index/homepage.jpg';

export const pageQuery = graphql`
  query {
    grid: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/films/**/*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 20
    ) {
      edges {
        node {
          frontmatter {
            title
            affiche {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    films: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { category: { ne: "Archives" } }
      }
      sort: { order: DESC, fields: [frontmatter___time] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            category
            meta
            time
            classe
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Cinelangues - Intégrez le cinéma dans l’apprentissage des langues latines !"
      description="Cinélangues est un dispositif cinématographique mis en place par des professeurs de langues et des professionnels du cinéma pour des professeurs de langues."
      pathname="/"
    />
    <div className="indexPage">
      <div className="container">
        <header className="masthead">
          <div className="row no-gutter h-100">
            <Flip bottom cascade>
              <div className="col-md-6 col-lg-5 header-content">
                <h1 className="mb-0">
                  Le cinéma intégré
                  <br />
                  dans les cours
                  <br />
                  de langues
                </h1>
              </div>
            </Flip>
            <div className="col-md-6 col-lg-7 header-image">
              <img src={homepage} alt="Etudiant" />
            </div>
          </div>
        </header>
      </div>
      <FeaturedPosts />
      <div className="container films films-home">
        <div className="row">
          <div className="col-lg-12 mb-2">
            <h2>Programmation 2024-2025</h2>
          </div>
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.films.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-5 col-md-7">
                              {post.frontmatter.category === 'Espagnol' ? (
                                <Flag code="es" height="11" />
                              ) : null}
                              {post.frontmatter.category === 'Allemand' ? (
                                <Flag code="de" height="11" />
                              ) : null}
                              {post.frontmatter.category === 'Italien' ? (
                                <Flag code="it" height="11" />
                              ) : null}
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-sm-7 col-md-5 badges">
                            {post.frontmatter.time != 'NA' ? (
                                <span className="badge badge-pill badge-danger">
                                  {post.frontmatter.time}
                                </span>
                              ) : null}
                              <span className="badge badge-pill badge-warning">
                                {post.frontmatter.classe}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
