import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const FeaturedPosts = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { glob: "**/content/blog/**/*.md" }
            frontmatter: { featured: { eq: "yes" }, status: { eq: "published" } }
          }
          limit: 3
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              id
              excerpt(pruneLength: 75)
              frontmatter {
                title
                category
                author_id
                author_name
                date
                cover {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="container actu-home">
        <div className="row">
          <div className="col-sm-8 mt-2">
            <h2>A la Une</h2>
          </div>
        </div>
        <div className="row">
          {data.allMarkdownRemark.edges.map((post) => (
            <div className="col-sm-12 col-md-6 col-lg-4" key={post.node.id}>
              <div className="card card-blog">
                <Link to={`/fr/blog${post.node.fields.slug}`}>
                  <Img
                    fluid={post.node.frontmatter.cover.childImageSharp.fluid}
                    alt={post.node.frontmatter.title}
                    className="card-img-top"
                  />
                </Link>
                <div className="card-body">
                  <Link to={`/fr/blog${post.node.fields.slug}`}>
                    <h4 className="card-title">
                      {post.node.frontmatter.title}<br />
                      <small className="badge badge-warning">
                        {post.node.frontmatter.category}
                      </small>
                    </h4>
                  </Link>
                </div>
                {/*
                    <div className="card-footer">
                      by {post.node.frontmatter.author_name} the
                      {post.node.frontmatter.date}
                    </div>
                  */}
              </div>
            </div>
          ))}
        </div>
      </section>
    )}
  />
);

export default FeaturedPosts;
